import moment from "moment"

const getEvent = (dateTime, time, events) => {
  let currentDateTime = dateTime
      .clone()
      .add(time.start, "hours")
  return events.find(d => d.startHour === currentDateTime.hours() && currentDateTime.format('dddd') === d.weekday)
}
const getColumns = (days, time, events) => {
  return days.map(day => getEvent(day.dateTime, time, events))
}
const getRow = (time, days, events) => {
  return {
    start: time.start, end: time.end, columns: getColumns(days, time, events)
  }
}

export const getColorFromString = (str) => {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = '#';
  for (var i = 0; i < 3; i++) {
      var value = (hash >> (i * 8)) & 0xFF;
      colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
}
export const mapTable = (days, times, events) => {
  return times.map(time => getRow(time, days, events))
}
export const remapEvents = dataIn => {
  let events = []
  if(!dataIn || !dataIn.items){
    return events
  }

  dataIn.items.filter(x => x.start).map(item => {
    let startMoment = moment(item.start.dateTime)
    let endMoment = moment(item.end.dateTime)
    let diff = moment
      .duration(endMoment.clone().diff(startMoment.clone()))
      .asHours()
    for (let i = 0; i < diff; i++) {
      let startTime = moment(startMoment.clone()).add(i, "hours")
      let endTime = moment(startMoment.clone()).add(1 + i, "hours")
      console.log(i, startTime, endTime)
      let currentDate = {
        key: item.summary,
        weekday: startTime.format('dddd'),
        startHour: startTime.hours(),
        endHour: endTime.hours(),
        first: i === 0
      }
      events.push(currentDate)
    }
  })
  return events
}